@charset "UTF-8";
@font-face {
  font-family: 'fluentdevelopment';
  src: url("../font/fluentdevelopment.eot");
  src: url("../font/fluentdevelopment.eot#iefix") format("embedded-opentype"), url("../font/fluentdevelopment.woff2") format("woff2"), url("../font/fluentdevelopment.woff") format("woff"), url("../font/fluentdevelopment.ttf") format("truetype"), url("../font/fluentdevelopment.svg#fluentdevelopment") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fluentdevelopment';
    src: url('../font/fluentdevelopment.svg#fluentdevelopment') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fluentdevelopment";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-phone:before {
  content: '\e800'; }

/* '' */
.icon-user:before {
  content: '\e801'; }

/* '' */
.icon-money:before {
  content: '\e802'; }

/* '' */
.icon-doc:before {
  content: '\e803'; }

/* '' */
.icon-params:before {
  content: '\e804'; }

/* '' */
.icon-briefcase:before {
  content: '\e805'; }

/* '' */
.icon-twitter:before {
  content: '\f099'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-github-circled:before {
  content: '\f09b'; }

/* '' */
.icon-gplus:before {
  content: '\f0d5'; }

/* '' */
.icon-mail-alt:before {
  content: '\f0e0'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/font/open-sans-300-normal.eot);
  src: local("Open Sans Light"), local("OpenSans-Light"), url(/font/open-sans-300-normal.woff2) format("woff"), url(/font/open-sans-300-normal.woff) format("woff"), url(/font/open-sans-300-normal.ttf) format("truetype"), url(/font/open-sans-300-normal.svg#OpenSans) format("svg"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/font/open-sans-400-normal.eot);
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(/font/open-sans-400-normal.woff2) format("woff"), url(/font/open-sans-400-normal.woff) format("woff"), url(/font/open-sans-400-normal.ttf) format("truetype"), url(/font/open-sans-400-normal.svg#OpenSans) format("svg"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(/font/open-sans-700-normal.eot);
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(/font/open-sans-700-normal.woff2) format("woff"), url(/font/open-sans-700-normal.woff) format("woff"), url(/font/open-sans-700-normal.ttf) format("truetype"), url(/font/open-sans-700-normal.svg#OpenSans) format("svg"); }

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  src: url(/font/quicksand-300-normal.eot);
  src: local("Quicksand Light"), local("Quicksand-Light"), url(/font/quicksand-300-normal.woff2) format("woff"), url(/font/quicksand-300-normal.woff) format("woff"), url(/font/quicksand-300-normal.ttf) format("truetype"), url(/font/quicksand-300-normal.svg#Quicksand) format("svg"); }

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url(/font/quicksand-400-normal.eot);
  src: local("Quicksand Regular"), local("Quicksand-Regular"), url(/font/quicksand-400-normal.woff2) format("woff"), url(/font/quicksand-400-normal.woff) format("woff"), url(/font/quicksand-400-normal.ttf) format("truetype"), url(/font/quicksand-400-normal.svg#Quicksand) format("svg"); }

.h1, h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-weight: 400;
  font-family: "Quicksand", sans-serif; }

.invisible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.avoid-break {
  break-inside: avoid; }

.middle {
  vertical-align: middle; }

.top, .column-container > div, .halves > div, .quarters > div, .thirds > div, .partners-page .profiles > div {
  vertical-align: top; }

.absolute {
  position: absolute; }

.relative {
  position: relative; }

.fixed {
  position: fixed; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.right {
  float: right; }

.left {
  float: left; }

.center {
  margin-left: auto;
  margin-right: auto; }

.center-text, .footer-search, footer .social, footer .organisation,
footer .our-company,
footer .our-services, header.banner nav, .page.homepage h3, .partners-page .profiles .profile, .page.search-page .search, .whois-page .whoisErrors {
  text-align: center; }

.shadow, header.banner nav {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3); }

.text-shadow-dark, header.banner .nav-item.active, header.banner .nav-item:hover, header.banner .nav-item:focus {
  text-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.5); }

.text-shadow-light, a:hover, a:focus {
  text-shadow: 0 0.2rem 0.2rem rgba(50, 50, 50, 0.4); }

.required {
  font-weight: bold; }

.font-body-bold, .footer-search input, .footer-search button, .button,
input[type="submit"],
input[type="reset"],
input[type="button"], form.contact label, .page.search-page .search input, .page.search-page .search button, .page.search-page .search-results .result .meta .published:before,
.page.search-page .search-results .result .meta .modified:before, .whois-page form.whoisLookup label, .skip-link {
  font-weight: 700; }

.font-heading-bold {
  font-weight: 700; }

.column-container, .halves, .quarters, .thirds, .partners-page .profiles {
  overflow: hidden;
  vertical-align: top; }
  .column-container:after, .halves:after, .quarters:after, .thirds:after, .partners-page .profiles:after {
    clear: both; }
  .column-container > div, .halves > div, .quarters > div, .thirds > div, .partners-page .profiles > div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin-bottom: 2rem; }
    .column-container > div:first-child, .halves > div:first-child, .quarters > div:first-child, .thirds > div:first-child, .partners-page .profiles > div:first-child {
      margin-left: 0; }

@media (min-width: 48em) {
  .halves > div, .quarters > div {
    width: 48%;
    margin-left: 4%;
    display: inline-block; } }

@media (min-width: 43.75em) {
  .thirds > div, .partners-page .profiles > div {
    width: 32%;
    margin-left: 2%;
    padding-left: .5rem;
    padding-right: .5rem;
    display: inline-block; } }

@media (min-width: 61.25em) {
  .thirds > div, .partners-page .profiles > div {
    width: 31%;
    margin-left: 3.5%;
    padding-left: 2rem;
    padding-right: 2rem;
    display: inline-block; } }

.quarters > div:nth-child(2n+1) {
  margin-left: 0; }

@media (min-width: 61.25em) {
  .quarters > div {
    width: 23%;
    margin-left: 2.6666666667%;
    display: inline-block; }
    .quarters > div:first-child {
      margin-left: 0; } }

textarea,
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"], select {
  height: 3.8rem;
  padding: .6rem 1rem;
  border: 0.1rem solid #bbb;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  background: transparent; }
  textarea:focus,
  input:focus[type="email"],
  input:focus[type="number"],
  input:focus[type="search"],
  input:focus[type="text"],
  input:focus[type="tel"],
  input:focus[type="url"],
  input:focus[type="password"], select:focus {
    outline: 0;
    border: 0.1rem solid #33c3f0; }
  textarea + .invalidMessage, input[type="email"] + .invalidMessage, input[type="number"] + .invalidMessage, input[type="search"] + .invalidMessage, input[type="text"] + .invalidMessage, input[type="tel"] + .invalidMessage, input[type="url"] + .invalidMessage, input[type="password"] + .invalidMessage, select + .invalidMessage {
    display: none;
    color: #ff4136;
    font-style: italic;
    margin: 0;
    padding: 1rem 0 0; }
  textarea.invalid,
  input.invalid[type="email"],
  input.invalid[type="number"],
  input.invalid[type="search"],
  input.invalid[type="text"],
  input.invalid[type="tel"],
  input.invalid[type="url"],
  input.invalid[type="password"], select.invalid {
    border: 0.1rem solid #ff4136; }
    textarea.invalid + .invalidMessage, input.invalid[type="email"] + .invalidMessage, input.invalid[type="number"] + .invalidMessage, input.invalid[type="search"] + .invalidMessage, input.invalid[type="text"] + .invalidMessage, input.invalid[type="tel"] + .invalidMessage, input.invalid[type="url"] + .invalidMessage, input.invalid[type="password"] + .invalidMessage, select.invalid + .invalidMessage {
      display: block; }

textarea,
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"] {
  /* property/value doesn't exist:
    appearance: none;
  */
  -webkit-appearance: none;
  -moz-appearance: none; }

textarea {
  min-height: 6.5rem; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

* {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  line-height: normal; }

body {
  color: #323232;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  padding-top: 7rem; }

a {
  text-decoration: none; }
  a, a:visited {
    color: #888;
    transition: text-shadow 0.5s; }
  a:active {
    color: #333;
    transition: none; }

img {
  width: 100%;
  height: auto; }

section {
  padding-top: 1rem;
  padding-bottom: 2rem; }
  @media (min-width: 43.75em) {
    section {
      padding-top: 6rem;
      padding-bottom: 8rem; } }

.container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  max-width: 160rem;
  padding: 0 3%; }
  @media (min-width: 43.75em) {
    .container {
      width: 93%;
      padding-left: 2%;
      padding-right: 2%; } }
  @media (min-width: 61.25em) {
    .container {
      width: 90%; } }

@media (min-width: 43.75em) {
  .hero .container {
    width: 95%;
    padding: 0; } }

.card {
  margin-top: 2rem; }

.page-not-found {
  margin-top: 10rem;
  margin-bottom: 10rem; }
  .page-not-found p {
    margin-bottom: 1.5rem;
    font-size: 2rem; }
  .page-not-found .instructions {
    margin: 0;
    font-size: 1.6rem; }

.footer-search {
  padding: 6rem 0;
  background: #eee; }
  .footer-search h6 {
    margin: 0 0 2rem;
    font-size: 3rem; }
    @media (min-width: 33.75em) {
      .footer-search h6 {
        margin: 0 0 3rem; } }
  .footer-search input, .footer-search button {
    color: #333;
    border-radius: 4px;
    background: transparent;
    box-sizing: border-box;
    font-size: 1.3rem;
    font-family: "Open Sans", sans-serif;
    height: 3.6rem;
    line-height: 3.6rem;
    vertical-align: top; }
  .footer-search input {
    width: 90%;
    max-width: 40rem;
    margin: 0 0 2rem;
    background: #fff; }
    @media (min-width: 33.75em) {
      .footer-search input {
        width: 50%;
        margin-right: 2rem; } }
  .footer-search button {
    border: 0.1rem solid #bbb;
    padding: 0 3rem;
    text-transform: uppercase;
    transition: color 0.5s, background 0.5s; }
    .footer-search button:hover {
      color: #fff;
      background: #333; }

footer .social {
  padding: 0;
  margin: 3rem 0; }
  footer .social:after {
    border-bottom: 0.1rem solid #333;
    width: 50%; }
  footer .social li {
    list-style: none outside none;
    display: inline-block;
    margin: 0 1%; }
    @media (min-width: 25em) {
      footer .social li {
        margin: 0 3%; } }
  footer .social .icon {
    color: #fff;
    padding: .3rem;
    font-size: 3rem;
    text-shadow: 0.1rem 0.1rem 0 #000, 0.1rem -0.1rem 0 #000, -0.1rem 0.1rem 0 #000, -0.1rem -0.1rem 0 #000;
    transition: all ease-out 0.5s; }
    @media (min-width: 38.875em) {
      footer .social .icon {
        font-size: 4rem; } }
    footer .social .icon:before {
      margin-left: 0;
      margin-right: 0; }
    footer .social .icon:hover {
      color: #000;
      text-shadow: .1rem .1rem 0 transparent, .1rem -.1rem 0 transparent, -.1rem .1rem 0 transparent, -.1rem -.1rem 0 transparent;
      transition: all ease 0.5s; }
    footer .social .icon.icon-facebook:hover {
      color: #3b5997; }
    footer .social .icon.icon-twitter:hover {
      color: #55acee; }
    footer .social .icon.icon-gplus:hover {
      color: #d64937; }
    footer .social .icon.icon-github-circled:hover {
      color: #3a3838; }
    footer .social .icon.icon-instagram:hover {
      color: #3f729b; }
    footer .social .icon.icon-linkedin:hover {
      color: #0073b2; }

footer .information {
  background: #eee;
  padding-bottom: 3rem;
  font-family: "Quicksand", sans-serif; }
  footer .information .label {
    color: #888; }
  footer .information p,
  footer .information a,
  footer .information a:visited {
    color: #7f7e7e;
    font-size: 1.6rem; }
  footer .information a {
    transition: color 0.5s; }
    footer .information a:hover {
      color: #323232; }

footer .organisation,
footer .our-company,
footer .our-services {
  padding-top: 4rem; }

footer .organisation .logo {
  width: 50%; }
  @media (min-width: 43.75em) {
    footer .organisation .logo {
      width: 75%; } }

footer .organisation .address-street,
footer .organisation .address-state,
footer .organisation .telephone {
  margin: .5rem; }

footer .organisation .abn,
footer .organisation .acn,
footer .organisation .duns {
  font-size: 1.4rem;
  margin: 0 auto; }

footer .organisation .telephone,
footer .organisation .acn {
  margin-top: 2rem; }

footer .organisation .address-street {
  margin-top: 0; }

footer .our-company .h6, footer .our-company h6,
footer .our-services .h6,
footer .our-services h6 {
  color: #4c4b4b;
  margin: 0 0 1rem;
  font-size: 2rem;
  position: relative;
  display: inline-block; }
  footer .our-company .h6:after, footer .our-company h6:after,
  footer .our-services .h6:after,
  footer .our-services h6:after {
    content: '';
    display: block;
    width: 140%;
    height: .1rem;
    margin: 1rem -20% 0;
    background: #b2b1b1; }

footer .our-company ul,
footer .our-services ul {
  margin: 0;
  padding-left: 1rem;
  list-style: none; }
  footer .our-company ul li a,
  footer .our-services ul li a {
    display: block;
    padding-top: .5rem;
    margin-top: .8rem; }
  footer .our-company ul li:first-child a,
  footer .our-services ul li:first-child a {
    padding-top: .4rem;
    margin-top: 0; }

footer .copyright {
  text-align: center;
  color: #989898;
  background: #333;
  padding: .8rem;
  font-size: 1.2rem; }

.sprite-development, .sprite-integration, .sprite-specialist-solutions {
  background: url("../images/sprites/homepage.svg") no-repeat; }

.sprite-development {
  background-position: 0 0; }

.sprite-development-dimensions {
  width: 93px;
  height: 76px; }

.sprite-integration {
  background-position: 50% 0; }

.sprite-integration-dimensions {
  width: 93px;
  height: 76px; }

.sprite-specialist-solutions {
  background-position: 100% 0; }

.sprite-specialist-solutions-dimensions {
  width: 93px;
  height: 76px; }

.sprite-common {
  display: block;
  height: 0;
  width: 50%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  background-size: 300%;
  /*
    Padding is based on width so height 0 and padding 100% results in a square
  */
  padding-top: 40.8602150538%; }

.accordion {
  margin: auto;
  width: 100%;
  margin-bottom: 2rem; }
  .accordion input[name="accordion"] {
    display: none; }
  .accordion .box {
    position: relative;
    background: #fff;
    min-height: 6.4rem;
    transition: all .15s ease-in-out; }
    .accordion .box:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      box-shadow: 0 -0.1rem 0 #e5e5e5, 0 0 0.2rem rgba(0, 0, 0, 0.12), 0 0.2rem 0.4rem rgba(0, 0, 0, 0.24); }
  .accordion .box-title {
    width: 100%;
    min-height: 6.4rem;
    line-height: 6.4rem;
    padding: 0 2rem;
    display: inline-block;
    cursor: pointer;
    -webkit-touch-callout: none;
    user-select: none; }
  .accordion header.box {
    background: #000;
    z-index: 100;
    cursor: initial;
    box-shadow: 0 -0.1rem 0 #e5e5e5, 0 0 0.2rem -0.2rem rgba(0, 0, 0, 0.12), 0 0.2rem 0.4rem -0.4rem rgba(0, 0, 0, 0.24); }
  .accordion header .box-title {
    margin: 0;
    font-weight: normal;
    line-height: normal;
    padding: 2rem;
    color: #fff;
    cursor: initial; }
  .accordion .box-content {
    width: 100%;
    padding: 3rem 2rem;
    color: #323232;
    display: none; }
  .accordion .box-close {
    position: absolute;
    height: 6.4rem;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    display: none; }
  .accordion input:checked + .box {
    height: auto;
    margin: 2rem 0;
    box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.16), 0 0.6rem 1.2rem rgba(0, 0, 0, 0.32); }
    .accordion input:checked + .box .box-title {
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.18); }
      .accordion input:checked + .box .box-title:before {
        transform: rotate(90deg); }
    .accordion input:checked + .box .box-content,
    .accordion input:checked + .box .box-close {
      display: inline-block; }
  .accordion .arrows div .box-title {
    padding-left: 4.4rem;
    width: 100%; }
    .accordion .arrows div .box-title:before {
      position: absolute;
      display: block;
      content: '\203a';
      left: 2rem;
      top: -.2rem;
      transition: transform .15s ease-in-out;
      color: rgba(0, 0, 0, 0.54); }

/*	-------------------------------------
	:: Buttons
	---------------------------------- */
.button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  font-size: 1.3rem;
  font-family: "Open Sans", sans-serif;
  position: relative;
  line-height: 1.4;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
  color: #bbb;
  display: inline-flex;
  margin: 0;
  padding: 0.875em 1.428571429em;
  letter-spacing: 0.2em; }
  .button::after,
  input[type="submit"]::after,
  input[type="reset"]::after,
  input[type="button"]::after {
    content: '';
    width: 80%;
    left: 50%;
    height: 1px;
    bottom: 0;
    transform: translatex(-50%);
    transition: width 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0.4s, background 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
    display: block;
    position: absolute;
    background: #bbb; }
  .button span,
  input[type="submit"] span,
  input[type="reset"] span,
  input[type="button"] span {
    display: block;
    position: absolute;
    transition: all 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9);
    width: 100%;
    top: 0;
    left: 0; }
    .button span::before, .button span::after,
    input[type="submit"] span::before,
    input[type="submit"] span::after,
    input[type="reset"] span::before,
    input[type="reset"] span::after,
    input[type="button"] span::before,
    input[type="button"] span::after {
      content: '';
      display: block;
      position: absolute;
      background: #bbb;
      transition: transform 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9); }
    .button span:first-child,
    input[type="submit"] span:first-child,
    input[type="reset"] span:first-child,
    input[type="button"] span:first-child {
      height: 100%; }
      .button span:first-child::before, .button span:first-child::after,
      input[type="submit"] span:first-child::before,
      input[type="submit"] span:first-child::after,
      input[type="reset"] span:first-child::before,
      input[type="reset"] span:first-child::after,
      input[type="button"] span:first-child::before,
      input[type="button"] span:first-child::after {
        width: 1px;
        height: 100%;
        bottom: 0;
        transform: scale3d(1, 0, 1);
        transform-origin: bottom center;
        transition: transform 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0.2s, background 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s; }
      .button span:first-child::before,
      input[type="submit"] span:first-child::before,
      input[type="reset"] span:first-child::before,
      input[type="button"] span:first-child::before {
        left: 0; }
      .button span:first-child::after,
      input[type="submit"] span:first-child::after,
      input[type="reset"] span:first-child::after,
      input[type="button"] span:first-child::after {
        right: 0; }
    .button span:last-child,
    input[type="submit"] span:last-child,
    input[type="reset"] span:last-child,
    input[type="button"] span:last-child {
      width: 100%;
      height: 1px; }
      .button span:last-child::before, .button span:last-child::after,
      input[type="submit"] span:last-child::before,
      input[type="submit"] span:last-child::after,
      input[type="reset"] span:last-child::before,
      input[type="reset"] span:last-child::after,
      input[type="button"] span:last-child::before,
      input[type="button"] span:last-child::after {
        height: 1px;
        width: 100%;
        bottom: 0;
        transform: scale3d(0, 1, 1);
        transition: transform 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s, background 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s; }
      .button span:last-child::before,
      input[type="submit"] span:last-child::before,
      input[type="reset"] span:last-child::before,
      input[type="button"] span:last-child::before {
        left: 0;
        transform-origin: bottom left; }
      .button span:last-child::after,
      input[type="submit"] span:last-child::after,
      input[type="reset"] span:last-child::after,
      input[type="button"] span:last-child::after {
        right: 0;
        transform-origin: bottom right; }
  .button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover {
    opacity: 1;
    outline: 0;
    color: #333 !important;
    text-decoration: none;
    transition: color 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s;
    /* Positioning */ }
    .button:hover::after,
    input[type="submit"]:hover::after,
    input[type="reset"]:hover::after,
    input[type="button"]:hover::after {
      width: 100%;
      background: #333;
      transition: width 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s, background 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s; }
    .button:hover span:first-child::before, .button:hover span:first-child::after,
    input[type="submit"]:hover span:first-child::before,
    input[type="submit"]:hover span:first-child::after,
    input[type="reset"]:hover span:first-child::before,
    input[type="reset"]:hover span:first-child::after,
    input[type="button"]:hover span:first-child::before,
    input[type="button"]:hover span:first-child::after {
      transform: scale3d(1, 1, 1);
      background: #333;
      transition: transform 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0.2s, background 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s; }
    .button:hover span:last-child::before, .button:hover span:last-child::after,
    input[type="submit"]:hover span:last-child::before,
    input[type="submit"]:hover span:last-child::after,
    input[type="reset"]:hover span:last-child::before,
    input[type="reset"]:hover span:last-child::after,
    input[type="button"]:hover span:last-child::before,
    input[type="button"]:hover span:last-child::after {
      transform: scale3d(1, 1, 1);
      background: #333;
      transition: transform 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0.4s, background 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9) 0s; }
  .button:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  input[type="button"]:focus {
    background: #333;
    color: white !important;
    transition: all 0.2s cubic-bezier(0.2, 0.3, 0.25, 0.9); }
    .button:focus::after,
    input[type="submit"]:focus::after,
    input[type="reset"]:focus::after,
    input[type="button"]:focus::after {
      opacity: 0; }
  .button.active,
  input[type="submit"].active,
  input[type="reset"].active,
  input[type="button"].active {
    color: #000 !important;
    border-color: #000; }
    .button.active:hover, .button.active:focus,
    input[type="submit"].active:hover,
    input[type="submit"].active:focus,
    input[type="reset"].active:hover,
    input[type="reset"].active:focus,
    input[type="button"].active:hover,
    input[type="button"].active:focus {
      background: transparent; }
  .button-container .button, .button-container
  input[type="submit"], .button-container
  input[type="reset"], .button-container
  input[type="button"] {
    margin-bottom: 10px; }

/*
	// Used for Career links
	.big-button {
		position: relative;
		display: block;
		width: 100%;
		padding: 21px 29px;
		background: $color-charcoal;
		text-shadow: none;
		text-align: left;
		font-size: 2rem;
		border: 1px solid #999;
		transition: all 0.2s $cubic-bezier;

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 50%;
			width: 45px;
			height: 38px;
			background: url("../img/arrow_right_short_gold.svg") 50% 50%/contain no-repeat;
			transform: translatey(-19px);
			right: 20px;
			transition: all 0.2s $cubic-bezier;
		}

		&:hover {
			background: darken($body-dark, 5%);

			&::after {
				right: 12px;
			}
		}
	}
*/
header.banner {
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
  height: 7rem;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5000; }
  header.banner .container {
    position: relative;
    height: 100%; }
  header.banner .icon,
  header.banner nav,
  header.banner .menuLabel {
    display: block;
    position: absolute;
    line-height: 7rem; }
  header.banner .icon {
    left: 1.5rem;
    filter: none; }
    header.banner .icon svg {
      transition: filter 0.5s; }
    header.banner .icon:hover svg,
    header.banner .icon:focus svg {
      filter: drop-shadow(0 1px 3px #555); }
  header.banner .menuLabel {
    right: 1.5rem; }
  header.banner nav {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 32rem;
    height: 100%;
    transition: opacity 0.5s, right 0.5s;
    background: #fff;
    z-index: 6000;
    overflow-y: auto; }
  header.banner #navToggle + nav {
    right: -32rem;
    opacity: 0; }
  header.banner #navToggle:checked + nav {
    right: 0;
    opacity: 1; }
  header.banner .menuLabel > .button,
  header.banner nav label.button {
    color: #333; }
  header.banner .navigation,
  header.banner .navigation li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
  header.banner .nav-item {
    display: block;
    text-decoration: none;
    font-family: inherit;
    font-weight: inherit;
    font-size: 2rem;
    text-transform: uppercase;
    color: #bbb;
    text-shadow: none;
    transition: color 0.5s, text-shadow 0.5s; }
    header.banner .nav-item.active, header.banner .nav-item:hover, header.banner .nav-item:focus {
      color: #333; }
  @media (min-width: 43.75em) {
    header.banner .button {
      display: none; }
    header.banner nav {
      position: absolute;
      top: auto;
      width: auto;
      max-width: none;
      height: auto;
      background: transparent;
      z-index: 6000;
      overflow-y: hidden;
      box-shadow: none; }
    header.banner #navToggle + nav,
    header.banner #navToggle:checked + nav {
      right: 1.5rem;
      opacity: 1; }
    header.banner .navigation,
    header.banner .navigation li {
      display: inline-block; }
    header.banner .nav-item {
      display: inline-block;
      padding: 0 .8rem; }
    header.banner .navigation li:last-child .nav-item {
      padding-right: 0; } }

.page.homepage .hero {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  color: #7f7f7f;
  background: #eee;
  background: url(../images/sand.jpg);
  background-position: top center;
  overflow: hidden;
  padding: 60px 0; }
  @media (min-width: 93.75em) {
    .page.homepage .hero {
      background-size: cover; } }
  @media (max-width: 33.8113875em) {
    .page.homepage .hero {
      padding: 11.0876586598vw 0; } }
  .page.homepage .hero h1,
  .page.homepage .hero h2 {
    margin: 0; }
  .page.homepage .hero .logo img {
    display: block;
    width: 641.1422px;
    height: auto;
    max-width: 118.4794311%;
    margin-top: -80px;
    margin-right: -50px;
    margin-bottom: -140px;
    margin-left: -50px; }
    @media (max-width: 33.8113875em) {
      .page.homepage .hero .logo img {
        margin-top: -14.7835448797vw;
        margin-bottom: -25.8712035395vw; } }
  .page.homepage .hero h2 {
    font-size: 3rem;
    font-weight: bold; }
  .page.homepage .hero .byline {
    font-size: 3.2rem;
    margin: 0 3.5rem 0 1rem;
    /*
      @include mq($from: bylinePadding, $until: logoFull) {
        margin: 0 1rem;
        padding-left: 65px / map-get($mq-breakpoints, logoFull) * 100%;
      }
*/ }
    @media (min-width: 31.25em) {
      .page.homepage .hero .byline {
        margin: 0 2rem 0 7rem; } }
    @media (max-width: 33.8113875em) {
      .page.homepage .hero .byline {
        font-size: 5.9134179519vw; } }
    @media (min-width: 61.25em) {
      .page.homepage .hero .byline {
        padding-left: 2rem; } }
    .page.homepage .hero .byline span:first-child {
      display: block; }
    .page.homepage .hero .byline .btn {
      margin-top: 1rem; }
  .page.homepage .hero .adjectives {
    color: #333; }

.page.homepage .content .thirds > div, .page.homepage .content .partners-page .profiles > div, .partners-page .page.homepage .content .profiles > div {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.about-page .vision {
  background: #eee;
  background: url(../images/road.jpg);
  background-position: top center;
  overflow: hidden;
  text-align: center; }
  .about-page .vision h3, .about-page .vision p {
    width: 80%;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 48em) {
      .about-page .vision h3, .about-page .vision p {
        width: 70%; } }
  .about-page .vision p {
    font-size: 1.1em; }
  @media (min-width: 93.75em) {
    .about-page .vision {
      background-size: cover; } }

.about-page .profiles.halves .profile .roles, .about-page .profiles.quarters .profile .roles,
.about-page .profiles.halves .profile .title,
.about-page .profiles.quarters .profile .title {
  display: block; }


.about-page .profiles.halves .profile .title,
.about-page .profiles.quarters .profile .title {
  font-size: 1.2em; }

.about-page .profiles.halves .profile .roles, .about-page .profiles.quarters .profile .roles {
  font-style: italic; }

.about-page .profiles.halves .profile img, .about-page .profiles.quarters .profile img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  display: block;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 34.375em) and (max-width: 47.99em) {
  .about-page .profiles.halves .profile:first-child img, .about-page .profiles.quarters .profile:first-child img,
  .about-page .profiles.halves .profile:nth-child(2n+1) img,
  .about-page .profiles.quarters .profile:nth-child(2n+1) img {
    float: left;
    margin-right: 1rem; }
  .about-page .profiles.halves .profile:nth-child(2n) img, .about-page .profiles.quarters .profile:nth-child(2n) img {
    float: right;
    margin-left: 1rem; } }

@media (min-width: 68.75em) {
  .about-page .profiles.halves .profile:first-child img, .about-page .profiles.quarters .profile:first-child img,
  .about-page .profiles.halves .profile:nth-child(2n+1) img,
  .about-page .profiles.quarters .profile:nth-child(2n+1) img {
    float: left;
    margin-right: 1rem; }
  .about-page .profiles.halves .profile:nth-child(2n) img, .about-page .profiles.quarters .profile:nth-child(2n) img {
    float: right;
    margin-left: 1rem; } }

.blog {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  margin-bottom: 10rem;
  width: 90%; }
  @media (min-width: 48em) {
    .blog {
      width: 75%;
      max-width: 80rem; } }
  .blog a.post-link {
    text-decoration: none;
    color: inherit;
    transition: color 0.5s; }
    .blog a.post-link > h2, .blog a.post-link > h3 {
      color: inherit; }
    .blog a.post-link:hover, .blog a.post-link:focus {
      color: inherit;
      text-shadow: none;
      color: #7f7f7f; }
  .blog .post-list {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .blog .post-list .post {
      padding-top: 3rem;
      padding-bottom: 3rem; }
    .blog .post-list hr {
      width: 95%;
      height: 0;
      margin-bottom: 2rem;
      margin-top: 2rem;
      border: 0;
      border-top: 1px solid #eee; }
  .blog .post .post-meta {
    font-size: 1.4rem;
    font-style: italic; }

form.contact {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem; }
  @media (min-width: 61.25em) {
    form.contact {
      width: 85%; } }
  @media (min-width: 81.25em) {
    form.contact {
      width: 70%; } }
  form.contact textarea {
    margin-bottom: 2rem; }
    form.contact textarea + .invalidMessage {
      margin: -2.5rem 0 2rem; }
  form.contact .halves:last-child, form.contact .quarters:last-child {
    margin-bottom: 0; }
  form.contact label {
    display: block;
    margin-bottom: 1rem; }
  form.contact input,
  form.contact select,
  form.contact textarea {
    width: 100%;
    box-sizing: border-box; }
  form.contact .g-recaptcha {
    margin-left: 0;
    overflow: hidden; }
    form.contact .g-recaptcha + div {
      padding: 2rem 0 0;
      text-align: center; }
      @media (min-width: 28.75em) {
        form.contact .g-recaptcha + div {
          padding: 0;
          position: absolute;
          top: 0;
          right: 0;
          text-align: right; } }
      form.contact .g-recaptcha + div button {
        color: #333;
        display: inline-block; }

@media (min-width: 43.75em) and (max-width: 61.24em) {
  .partners-page img.partner-logo {
    float: right;
    width: 25rem;
    margin-left: 2rem;
    margin-bottom: 2rem; } }

@media (min-width: 61.25em) {
  .partners-page img.partner-logo {
    float: right;
    width: 30rem;
    margin-left: 2rem;
    margin-bottom: 2rem; } }

.partners-page .profiles .profile {
  /* Remove this block once we've got more than one partner! */
  font-size: 1.4rem;
  margin: 4rem auto; }
  .partners-page .profiles .profile img.partner-logo {
    width: 200px;
    float: none; }
  .partners-page .profiles .profile, .partners-page .profiles .profile:first-child {
    margin-left: auto;
    margin-right: auto;
    display: block; }
    @media (min-width: 43.75em) {
      .partners-page .profiles .profile, .partners-page .profiles .profile:first-child {
        width: 50rem; } }

.page.search-page .search {
  padding: 6rem 0;
  background: #eee; }
  .page.search-page .search h1 {
    margin: 0 0 2rem;
    font-size: 3rem; }
    @media (min-width: 33.75em) {
      .page.search-page .search h1 {
        margin: 0 0 3rem; } }
  .page.search-page .search input, .page.search-page .search button {
    color: #333;
    border-radius: 4px;
    background: transparent;
    box-sizing: border-box;
    font-size: 1.3rem;
    font-family: "Open Sans", sans-serif;
    height: 3.6rem;
    line-height: 3.6rem;
    vertical-align: top; }
  .page.search-page .search input {
    width: 90%;
    max-width: 40rem;
    margin: 0 0 2rem;
    background: #fff; }
    @media (min-width: 33.75em) {
      .page.search-page .search input {
        width: 50%;
        margin-right: 2rem; } }
  .page.search-page .search button {
    border: 0.1rem solid #bbb;
    padding: 0 3rem;
    text-transform: uppercase;
    transition: color 0.5s, background 0.5s; }
    .page.search-page .search button:hover {
      color: #fff;
      background: #333; }

.page.search-page .search-results {
  margin-bottom: 2rem; }
  .page.search-page .search-results .result {
    padding: 2rem;
    margin: 2rem; }
    .page.search-page .search-results .result h3 {
      margin: 0;
      font-size: 2.5rem; }
      .page.search-page .search-results .result h3 a,
      .page.search-page .search-results .result h3 a:visited {
        color: #323232; }
    .page.search-page .search-results .result .meta .published,
    .page.search-page .search-results .result .meta .modified {
      display: block;
      margin: 1.5rem 0 0;
      font-size: 1.5rem; }
    .page.search-page .search-results .result .meta .published:before {
      content: 'Published: '; }
    .page.search-page .search-results .result .meta .modified {
      margin-top: 0; }
      .page.search-page .search-results .result .meta .modified:before {
        content: 'Last Updated: '; }
    .page.search-page .search-results .result .excerpt {
      margin: 1.5rem 0 0; }
      .page.search-page .search-results .result .excerpt .match {
        font-weight: bold; }

.services-page h1 {
  margin-top: 2em; }

.services-page .shaded {
  background: #eee; }

.whois-page form.whoisLookup {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem; }
  @media (min-width: 61.25em) {
    .whois-page form.whoisLookup {
      width: 85%; } }
  @media (min-width: 81.25em) {
    .whois-page form.whoisLookup {
      width: 70%; } }
  .whois-page form.whoisLookup label {
    display: block;
    margin-bottom: 1rem; }
  .whois-page form.whoisLookup input {
    width: 100%;
    box-sizing: border-box; }
  .whois-page form.whoisLookup .button {
    margin-top: 1rem; }

.whois-page .whoisErrors {
  margin-bottom: 0;
  margin-top: 0;
  padding: 2rem 0;
  color: #ff4136; }

.skip-link {
  background-color: #fff;
  box-shadow: 0 0 0.2rem 0.2rem black;
  color: #3498db;
  display: block;
  font-size: 1.4rem;
  height: auto;
  left: .6rem;
  line-height: normal;
  padding: 1.5rem 2.3rem 1.4rem;
  position: absolute;
  text-decoration: none;
  top: -10rem;
  transition: top .75s ease-out;
  width: auto;
  z-index: 100000; }

.skip-link:focus {
  top: .7rem;
  transition: top 0s; }

/*# sourceMappingURL=fluent-development.css.map */